import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const MinhasVagas = loadable(() => import('@screens/minhas_vagas/MinhasVagas'));
function Index(props) {
  return (
    <Layout {...props}>
      <MinhasVagas {...props} />
    </Layout>
  );
}

export default Index;
